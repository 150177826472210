import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/pre-sales/Hero";
import Features from "../sections/pre-sales/Features";
import Content0 from "../sections/pre-sales/Content0";
import Content1 from "../sections/pre-sales/Content1";
import Content2 from "../sections/pre-sales/Content2";
import Content3 from "../sections/pre-sales/Content3";

const PreSales = () => {
  const onClickInstantDemo = () => {
    if (window && window.INSTANT_DEMO) {
      window.INSTANT_DEMO.initiate_flow('30199c50-b163-4b9f-99e1-4cf7f9177f0f')
    }

    if (window !== "undefined" && window.gtag) {
      window.gtag("event", "conversion", { send_to: 'AW-411216111/QZPVCKPc9_0BEO_RisQB' })
    }
  }

  return (
    <>
      <PageWrapper
        headerConfig={{
          isFluid: true,
        }}
      >
        <Hero onClickInstantDemo={onClickInstantDemo} />
        <Features />
        <Content0 />
        <Content1 />
        <Content2 />
        <Content3 />
      </PageWrapper>
    </>
  );
};

export default PreSales;