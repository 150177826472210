import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Content = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "pre-sales/roles-2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section bg-default-6 pt-lg-15 pt-10 pb-13 pb-lg-21">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              sm="10"
              lg="6"
              className="offset-lg-1 order-lg-2 mb-9 mb-lg-0"
            >
              <div
                className="double-image-group-opacity rounded-10"
                style={{border: '8px solid #161C2D'}}
              >
                <Img
                  className="main-img w-100"
                  alt="Roles"
                  fluid={data.heroImage.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col sm="10" lg="5" className="order-lg-1">
              <div className="content-text">
                <h2 className="gr-text-4 mb-7">
                  What Role Best Describes You?
                </h2>
                <p className="gr-text-8 mb-0">
                  Scale yourself. No longer will you have to go through the process of demoing the priorities to each and every decision maker, instead, let them tell you what is important and let Instant Demo do the rest. You can personalize the demo by team members, or keep it general.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
