import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Content = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "pre-sales/personalize.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="content-section bg-default-6 pb-13 pb-lg-21">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="7">
              <div
                className="double-image-group-opacity"
              >
                <Img
                  className="main-img w-100"
                  alt="Image 1"
                  fluid={data.heroImage.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col sm="10" lg="5">
              <div className="content-text pt-11 pt-lg-0 pl-xl-7">
                <h2 className="gr-text-4 mb-7">
                  Tailor Every Demo to Every Decision Maker
                </h2>
                <p className="gr-text-8 mb-0">
                  Have each stakeholder do it, watch them share internally, and see the analytics to know when they are hot or cold.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
