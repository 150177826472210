import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Content = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "pre-sales/nothing-to-chance.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section bg-default-6 pb-13 pb-lg-21">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              sm="10"
              lg="6"
              className="offset-lg-1 order-lg-2 mb-9 mb-lg-0"
            >
              <div
                className="double-image-group-opacity"
              >
                <Img
                  className="main-img w-100"
                  alt="Image 2"
                  fluid={data.heroImage.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col sm="10" lg="5" className="order-lg-1">
              <div className="content-text">
                <h2 className="gr-text-4 mb-7">
                  Leave Nothing to Chance
                </h2>
                <p className="gr-text-8 mb-0">
                  Ever had a prospect need to leave a call early? An internet outage? Your product goes down mid-demo? Data not loading properly? Can’t align schedules for 10 decision makers? None of that matters when you have an Instant Demo.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
